
function moveToSameElementOnSibling (activeElement: Element, sibling: HTMLElement, key: string) {
  const classes = "." + activeElement.className.split(" ").join(".");
  const sameElementTypeAsActive = sibling.querySelector(classes) as HTMLElement;
  const empty = sameElementTypeAsActive.innerHTML === "<!----><!---->";
  if (sameElementTypeAsActive && !empty) {
    sameElementTypeAsActive.focus();
  } else {
    // create a new keyboard event that shift tab, followed by whichever arrow was pressed earlier
    const previous = activeElement.previousElementSibling as HTMLElement;
    previous.focus();
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    moveFocusByRow(new KeyboardEvent("keydown", { key }));
  }
};

export default function moveFocusByRow (e: KeyboardEvent) {
  // if we are within the list row table
  if (document.activeElement?.closest(".list-row")) {
    // grab the closest list row
    const element = document.activeElement.closest(".list-row");
    // move the right direction based on key pressed
    if (element && (e.key === "ArrowUp" || e.key === "Up")) {
      if (element.previousElementSibling) {
        const previousSibling = element.previousElementSibling as HTMLElement;
        if (document.activeElement.className.includes("list-row")) {
          previousSibling.focus();
          return;
        }
        moveToSameElementOnSibling(document.activeElement, previousSibling, e.key);
      }
    } else if (element && (e.key === "ArrowDown" || e.key === "Down")) {
      if (element.nextElementSibling) {
        const nextSibling = element.nextElementSibling as HTMLElement;
        if (document.activeElement.className.includes("list-row")) {
          nextSibling.focus();
          return;
        }
        moveToSameElementOnSibling(document.activeElement, nextSibling, e.key);
      }
    }
  }
};
