<template>
  <div class="text-center">
    <v-row v-for="i in Math.floor(pageSize/3)" :key="i" >
      <v-col cols="12" md="4">
        <GridViewCard
          v-if="tablatedContent[(3*i)-3]"
          :material="tablatedContent[(3*i)-3]"
          :index="(3*i)-3"
          @onOpenPreview='$emit("onOpenPreview", $event)'
          @onCartLimitExceeded="$emit('onCartLimitExceeded')"
          @hook:mounted="onCardMounted(content.length, pageSize, (3*i)-3)"
        />
      </v-col>
      <v-col cols="12" md="4">
        <GridViewCard
          v-if="tablatedContent[(3*i)-2]"
          :material="tablatedContent[(3*i)-2]"
          :index="(3*i)-2"
          @onOpenPreview='$emit("onOpenPreview", $event)'
          @onCartLimitExceeded="$emit('onCartLimitExceeded')"
          @hook:mounted="onCardMounted(content.length, pageSize, (3*i)-2)"
        />
      </v-col>
      <v-col cols="12" md="4">
        <GridViewCard
          v-if="tablatedContent[(3*i)-1]"
          :material="tablatedContent[(3*i)-1]"
          :index="(3*i)-1"
          @onOpenPreview='$emit("onOpenPreview", $event)'
          @onCartLimitExceeded="$emit('onCartLimitExceeded')"
          @hook:mounted="onCardMounted(content.length, pageSize, (3*i)-1)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import GridViewCard from "./GridViewCard.vue";

import TableSettingsModule from "../store/modules/TableSettings/tableSettings";

export default defineComponent({
  components: {
    GridViewCard,
  },
  props: {
    content: {
      type: Array,
      default: () => ([]),
    },
    onCardMounted: {
      type: Function,
    },
  },
  setup (props) {
    const pageNumber = computed(() => TableSettingsModule.pageNumber);
    const pageSize = computed(() => TableSettingsModule.rowsPerPage);
    const viewingFavorites = computed(() => TableSettingsModule.viewingFavorites);
    const tablatedContent = computed(() => {
      if (viewingFavorites.value) {
        return props.content.slice(
          (pageNumber.value - 1) * pageSize.value,
          pageNumber.value * pageSize.value);
      } else {
        return props.content;
      }
    });
    return {
      pageNumber, pageSize, tablatedContent,
    };
  },
});
</script>

<style lang="scss" scoped>
  .table-wrapper {
    display: flex;
    justify-content: space-around;
  }
</style>
