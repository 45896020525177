import { ContentList } from "@/models/content/content";

function languagesList (material) {
  const baseLanguages = material.OtherLanguages.filter(l => ["en", "es"].includes(l.Code));
  const otherLanguages = material.OtherLanguages.filter(l => !["en", "es"].includes(l.Code));
  otherLanguages.sort((a, b) => {
    if (a.DisplayName?.toUpperCase() > b.DisplayName?.toUpperCase()) return 1;
    if (a.DisplayName?.toUpperCase() < b.DisplayName?.toUpperCase()) return -1;
    return 0;
  });
  return { baseLanguages, otherLanguages };
}

function applyDisplaySettings (content, showPrintButton, showShareButton) {
  for (const key in content as unknown as ContentList) {
    const element = content[key];
    element.ShowPrintButton = showPrintButton;
    element.ShowShareButton = showShareButton;
    element.LanguagesList = languagesList(element);
  }
  return content;
}

export default applyDisplaySettings;
