
import { defineComponent, ref, computed, watch, onMounted } from "vue";
import SkeletonLoader from "./SkeletonLoader.vue";
import GridTable from "./GridTable.vue";

import ContentModule from "../store/modules/Content/content";
import DiagnosticsService from "../services/diagnosticsService";
import UserModule from "../store/modules/User/user";
import TableSettingsModule from "../store/modules/TableSettings/tableSettings";
import applyDisplaySettings from "@/utility/applyDisplaySettings";
import UIConfigurationModule from "@/store/modules/UIConfiguration/uiConfiguration";

import moveFocusByRow from "@/utility/moveWithinTable";

import PreviewModalFunctionality from "@/composables/PreviewModalFunctionality";

const CartLimitExceededDialog = () => import("./CartLimitExceededDialog.vue");
const FolderGridCard = () => import("./FolderGridCard.vue");
const FolderListCard = () => import("./FolderListCard.vue");
const GridViewCard = () => import("./GridViewCard.vue");
const ListViewRow = () => import("./ListViewRow.vue");
const PreviewModal = () => import("./PreviewModal.vue");

export default defineComponent({
  name: "ContentContainer",
  components: {
    ListViewRow,
    FolderListCard,
    FolderGridCard,
    PreviewModal,
    CartLimitExceededDialog,
    SkeletonLoader,
    GridViewCard,
    GridTable,
  },
  props: {
    content: {
      type: [Array, Object],
    },
    foldersContent: {
      type: [Array, Object],
    },
    foldersView: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isFilterOpen: {
      type: Boolean,
      default: false,
    },
    activeTab: {
      type: String,
      default: "",
    },
    numberOfTaxonomies: {
      type: Number,
      default: 0,
    },
    firstMount: {
      type: Boolean,
      default: false,
    },
    favoritesView: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const isGoToTopVisible = ref(false);
    const isInitialContentContainerLoad = ref(props.firstMount);
    const {
      previewMaterial,
      isPreviewLoading,
      isPreviewVisible,
      nextPage,
      reOpenPreview,
      pageNumber,
      itemsPerPage,
      openPreviewModal,
      closePreview,
      setPreviewInfo,
      getTotalTitleCount,
      showPreviousContentItem,
      showNextContentItem,
    } = PreviewModalFunctionality(props);
    const onScroll = (e) => {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      isGoToTopVisible.value = top > 20;
    };
    const onCartLimitExceeded = async () => {
      UIConfigurationModule.setCartLimitExceededDialogVisibility(true);
    };

    const selectedMaterials = computed(() => {
      return ContentModule.getselectedMaterials;
    });

    const isGridView = computed(() => {
      return UserModule.isGridView;
    });

    const selectFolder = (folderId) => {
      emit("selectFolder", folderId);
    };

    const viewingFolderItems = computed(() => TableSettingsModule.viewingFolders);

    const showPrintButton = (): boolean => {
      return UserModule.displaySettings.printButton.visibility === 1;
    };
    const showShareButton = (): boolean => {
      return UserModule.displaySettings.publishButton.visibility === 1;
    };

    const applyDisplaySettingsToContent = (content) => {
      const _showPrintButton = showPrintButton();
      const _showShareButton = showShareButton();
      if (Array.isArray(content)) {
        content = applyDisplaySettings(content, _showPrintButton, _showShareButton);
      } else {
        content = Object.keys(content).map((key) => content[key]);
        content = applyDisplaySettings(content, showPrintButton, showShareButton);
      }
      return content;
    };

    const contentWithDisplaySettings = computed(() => applyDisplaySettingsToContent(props.content));

    const hideSpecialMessage = computed(() => {
      if ((props.activeTab === "Impressions" && UIConfigurationModule.getUIConfiguration.hideImpressionsTabSpecialMessage)) {
        return true;
      }
      return false;
    });

    const isCartLimitExceededDialogVisible = computed(() => UIConfigurationModule.getCartLimitExceededDialogVisibility);

    // When the last component of recommended content (list view row or grid card)
    // has mounted, we call this function to log on the server side for metrics
    // purposes. See TKOF-3445 for more details.
    const logLastRecommendedContentLoaded = (contentCount, pageSize, myIndex) => {
      // Handle the case where we don't have a 'full' page of data
      if (contentCount < pageSize) {
        pageSize = contentCount;
      }

      // Am I the last component of recommended content within my page?
      if (myIndex + 1 === pageSize) {
        // Is this the first time the content container has been mounted?
        if (isInitialContentContainerLoad.value) {
          isInitialContentContainerLoad.value = false;
          DiagnosticsService.initialPageLoadCompleted();
        }
      }
    };
    watch(() => props.content, () => {
      if (reOpenPreview.value) {
        let index = 0;
        if (!nextPage.value) {
          index = itemsPerPage.value - 1;
        }
        setPreviewInfo(contentWithDisplaySettings.value[index], index);
        nextPage.value = false;
        reOpenPreview.value = false;
      }
    });
    onMounted(() => {
      if (props.firstMount) {
        addEventListener("keydown", moveFocusByRow);
      }
    });

    return {
      isGoToTopVisible,
      onScroll,
      selectedMaterials,
      isGridView,
      isPreviewLoading,
      isPreviewVisible,
      isCartLimitExceededDialogVisible,
      openPreviewModal,
      previewMaterial,
      selectFolder,
      onCartLimitExceeded,
      contentWithDisplaySettings,
      pageNumber,
      itemsPerPage,
      viewingFolderItems,
      hideSpecialMessage,
      getTotalTitleCount,
      logLastRecommendedContentLoaded,
      closePreview,
      setPreviewInfo,
      showPreviousContentItem,
      showNextContentItem,
    };
  },
});
